// src/stores/authStore.ts
import { defineStore } from 'pinia'
import {
  Commissions,
  confirmTransfer,
  Deposits,
  fetchDepositAddress,
  fetchWalletInfo,
  initiateTransfer,
  requestWalletPayout,
  Transfers,
  validateReceiver,
  WalletDetails,
  WalletPayouts,
} from '../utils/api-helpers'
import { ApiError } from '../utils/api-client'

interface WalletState {
  loading: boolean
  commissionsLogs: Commissions[]
  walletDetails: WalletDetails | null
  payouts: WalletPayouts[]
  transfers: Transfers[]
  deposits: Deposits[]
  depositAddress: string | null
  depositQrCode: {
    status: string
    qr_code: string
    payment_uri: string
  } | null
}

export const useWalletStore = defineStore('wallet', {
  state: (): WalletState => ({
    loading: true,
    walletDetails: null,
    commissionsLogs: [],
    deposits: [],
    payouts: [],
    transfers: [],
    depositAddress: null,
    depositQrCode: null,
  }),
  getters: {},
  actions: {
    async getWalletInfo() {
      try {
        this.loading = true
        const walletInfo = await fetchWalletInfo()
        if (walletInfo.success) {
          this.loading = false
          this.walletDetails = walletInfo.data.walletDetails
          this.payouts = walletInfo.data.walletDetails.payouts
          this.deposits = walletInfo.data.deposits
          this.commissionsLogs = walletInfo.data.commissionsLogs
          this.transfers = walletInfo.data.transfers
          return { success: true, data: walletInfo.data, message: walletInfo.message }
        }

        const msg = walletInfo.message.length == 0 ? walletInfo.message : walletInfo.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
    async getDepositAddress() {
      try {
        const depositAddressInfo = await fetchDepositAddress()
        if (depositAddressInfo.success) {
          this.depositAddress = depositAddressInfo.data.address
          this.depositQrCode = depositAddressInfo.data.qrCode

          return { success: true, data: depositAddressInfo.data, message: depositAddressInfo.message }
        }

        const msg = depositAddressInfo.message.length == 0 ? depositAddressInfo.message : depositAddressInfo.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
    async handleWalletTransferValidate(receiverEmail: string) {
      try {
        const receiverEmailValidation = await validateReceiver(receiverEmail)
        if (receiverEmailValidation.success) {
          return { success: true, data: receiverEmailValidation.data, message: receiverEmailValidation.message }
        }
        const msg =
          receiverEmailValidation.message.length == 0 ? receiverEmailValidation.message : receiverEmailValidation.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
    async handleWalletTransferInitiation(receiverEmail: string, amount: string) {
      try {
        const initiatingTransfer = await initiateTransfer(receiverEmail, amount)
        if (initiatingTransfer.success) {
          return { success: true, data: initiatingTransfer.data, message: initiatingTransfer.message }
        }
        const msg = initiatingTransfer.message.length == 0 ? initiatingTransfer.message : initiatingTransfer.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
    async handleWalletTransferConfirm(transferId: string, otp: string) {
      try {
        const confirmingTransfer = await confirmTransfer(transferId, otp)
        if (confirmingTransfer.success) {
          this.getWalletInfo()
          return { success: true, data: confirmingTransfer.data, message: confirmingTransfer.message }
        }
        const msg = confirmingTransfer.message.length == 0 ? confirmingTransfer.message : confirmingTransfer.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
    async handleWalletPayoutRequest(walletAddress: string, amount: string) {
      try {
        const payoutRequest = await requestWalletPayout(walletAddress, amount)
        if (payoutRequest.success) {
          this.getWalletInfo()
          return { success: true, data: payoutRequest.data, message: payoutRequest.message }
        }
        const msg = payoutRequest.message.length == 0 ? payoutRequest.message : payoutRequest.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
  },
})
