export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'Dashboard',
      meta: {
        icon: 'dashboard',
      },
    },
    {
      name: 'referral',
      displayName: 'Referral',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'wallet',
      displayName: 'Wallet',
      meta: {
        icon: 'account_balance_wallet',
      },
    },
    {
      name: 'subscriptions',
      displayName: 'Subscription',
      meta: {
        icon: 'subscriptions',
      },
    },
    {
      name: 'profile',
      displayName: 'Profile',
      meta: {
        icon: 'person',
      },
    },

    {
      name: 'faq',
      displayName: 'FAQs',
      meta: {
        icon: 'quiz',
      },
    },
  ] as INavigationRoute[],
}
