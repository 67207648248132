import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import { resetStores, useStores } from '../stores/reset-stores'
import { tokenManager } from '../utils/token-manager'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' },
  },
  {
    name: 'admin',
    path: '/app',
    component: AppLayout,
    redirect: { path: '/' },
    meta: { requiresAuth: true },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/dashboard/Dashboard.vue'),
      },
      {
        name: 'referral',
        path: 'referral',
        component: () => import('../pages/referral/ReferralsPage.vue'),
      },
      {
        name: 'wallet',
        path: 'wallet',
        component: () => import('../pages/wallet/WalletPage.vue'),
      },
      {
        name: 'subscriptions',
        path: 'subscriptions',
        component: () => import('../pages/subscription/SubscriptionPage.vue'),
      },
      {
        name: 'profile',
        path: 'profile',
        component: () => import('../pages/profile/ProfilePage.vue'),
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
    ],
  },
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: '/',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'verify',
        path: 'verify',
        component: () => import('../pages/auth/VerifyEmail.vue'),
      },
      {
        name: 'registered',
        path: 'registered',
        component: () => import('../pages/auth/RegistrationComplete.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!tokenManager.getToken()

  if (to.name === 'login' && isAuthenticated) {
    // Redirect to dashboard if user is already logged in
    next({ name: 'dashboard' })
  } else if (to.meta.requiresAuth && !isAuthenticated) {
    const stores = useStores()
    resetStores(Object.values(stores))

    // Redirect to login page if the route requires auth and user is not authenticated
    next({ name: 'login', query: { redirect: to.fullPath } })
  } else {
    // Proceed navigation
    next()
  }
})

export default router
