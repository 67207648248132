// src/utils/api.ts
import axios from 'axios'
import { tokenManager } from './token-manager'

export const createApiClient = (getToken: () => string | null) => {
  const apiClient = axios.create({
    baseURL: 'https://api.vta.one',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  apiClient.interceptors.request.use(
    (config) => {
      const token = getToken()
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { status, data } = error.response

        if (
          status === 401 &&
          (data.message === 'Invalid access token.' || data.message === 'Access token has expired, login again.')
        ) {
          // Only clear the token
          tokenManager.clearToken()
        }
      }
      return Promise.reject(error)
    },
  )

  return apiClient
}

export interface BackendApiResponse<T> {
  success: boolean
  data: T
  message: string
  error: string
}
export interface ApiResponse<T> {
  success: boolean
  data: T
  message: string
  error: string
  statusCode: number
}

export class ApiError extends Error {
  statusCode: number
  data?: any

  constructor(message: string, status: number, data?: any) {
    super(message)
    this.name = 'ApiError'
    this.statusCode = status
    this.data = data
  }
}

export const getRequest = async <T>(url: string, params?: Record<string, any>): Promise<ApiResponse<T>> => {
  try {
    const apiClient = createApiClient(() => tokenManager.getToken())
    const response = await apiClient.get<BackendApiResponse<T>>(url, { params })
    return {
      statusCode: response.status,
      data: response.data.data,
      error: response.data.error,
      message: response.data.message,
      success: response.data.success,
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new ApiError(error.message, error.response?.status ?? 500, error.response?.data)
    }
    throw error
  }
}

export const postRequest = async <T>(url: string, data?: any): Promise<ApiResponse<T>> => {
  try {
    const apiClient = createApiClient(() => tokenManager.getToken())

    const response = await apiClient.post<BackendApiResponse<T>>(url, data)
    return {
      statusCode: response.status,
      data: response.data.data,
      error: response.data.error,
      message: response.data.message,
      success: response.data.success,
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new ApiError(error.message, error.response?.status ?? 500, error.response?.data)
    }
    throw error
  }
}
