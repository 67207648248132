// src/stores/resetStores.ts

import { Store } from 'pinia'

export const resetStores = (stores: Store[]) => {
  stores.forEach((store) => store.$reset())
}

import { useAuthStore } from './auth-store'
import { useDashboardStore } from './dashboard-store'
import { useReferralStore } from './referral-store'
import { useSubscriptionStore } from './subscription-store'
import { useWalletStore } from './wallet-store'

export const useStores = () => {
  const authStore = useAuthStore()
  const dashboardStore = useDashboardStore()
  const referralStore = useReferralStore()
  const subscriptionStore = useSubscriptionStore()
  const walletStore = useWalletStore()

  return {
    authStore,
    dashboardStore,
    referralStore,
    subscriptionStore,
    walletStore,
  }
}
