// src/utils/tokenManager.ts

let inMemoryToken: string | null = null

export const tokenManager = {
  setToken(token: string): void {
    inMemoryToken = token
    try {
      if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem('auth_token', token)
      }
    } catch (error) {
      console.error('Error setting token in localStorage:', error)
    }
  },

  getToken(): string | null {
    if (inMemoryToken) {
      return inMemoryToken
    }

    try {
      if (typeof window !== 'undefined' && window.localStorage) {
        const token = window.localStorage.getItem('auth_token')
        if (token) {
          inMemoryToken = token
        }
        return token
      }
    } catch (error) {
      console.error('Error getting token from localStorage:', error)
    }

    return null
  },

  clearToken(): void {
    inMemoryToken = null
    try {
      if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.removeItem('auth_token')
      }
    } catch (error) {
      console.error('Error clearing token from localStorage:', error)
    }
  },
}
