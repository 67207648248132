// src/stores/authStore.ts
import { defineStore } from 'pinia'
import { fetchReferralStats, fetchReferralTree, ReferralStats, TransformedReferralTree } from '../utils/api-helpers'
import { ApiError } from '../utils/api-client'

interface ReferralState {
  loading: boolean
  stats: ReferralStats | null
  tree: TransformedReferralTree | null
}

export const useReferralStore = defineStore('referral', {
  state: (): ReferralState => ({
    loading: true,
    stats: null,
    tree: null,
  }),
  getters: {},
  actions: {
    async referralStats() {
      try {
        this.loading = true
        const referralStatsInfo = await fetchReferralStats()
        if (referralStatsInfo.success) {
          this.loading = false
          this.stats = referralStatsInfo.data
          return { success: true, data: referralStatsInfo.data, message: referralStatsInfo.message }
        }

        const msg = referralStatsInfo.message.length == 0 ? referralStatsInfo.message : referralStatsInfo.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
    async referralTree() {
      try {
        this.loading = true
        const referralTreeInfo = await fetchReferralTree()
        if (referralTreeInfo.success) {
          this.loading = false
          this.tree = referralTreeInfo.data.tree
          return { success: true, data: referralTreeInfo.data.tree, message: referralTreeInfo.message }
        }

        const msg = referralTreeInfo.message.length == 0 ? referralTreeInfo.message : referralTreeInfo.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
  },
})
