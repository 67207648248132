// src/stores/authStore.ts
import { defineStore } from 'pinia'
import { fetchSubscriptionStatus, purchaseSubscription, Subscription } from '../utils/api-helpers'
import { ApiError } from '../utils/api-client'
import { UserSubscriptionStatusResp } from './dashboard-store'

interface SubscriptionState {
  subscriptionStatus: number | null
  subscriptionStatusEnum: UserSubscriptionStatusResp | null
  subscription: Subscription | null
}

function getStatusEnum(status: number): UserSubscriptionStatusResp {
  return status as UserSubscriptionStatusResp
}

export const useSubscriptionStore = defineStore('subscription', {
  state: (): SubscriptionState => ({
    subscriptionStatus: null,
    subscriptionStatusEnum: null,
    subscription: null,
  }),
  getters: {},
  actions: {
    async getSubscriptionStatus() {
      try {
        const subscriptionStatusInfo = await fetchSubscriptionStatus()
        if (subscriptionStatusInfo.success) {
          this.subscriptionStatus = subscriptionStatusInfo.data.subscriptionStatus
          this.subscriptionStatusEnum = getStatusEnum(subscriptionStatusInfo.data.subscriptionStatus)
          this.subscription = subscriptionStatusInfo.data.subscription
          return { success: true, data: subscriptionStatusInfo.data, message: subscriptionStatusInfo.message }
        }

        const msg =
          subscriptionStatusInfo.message.length == 0 ? subscriptionStatusInfo.message : subscriptionStatusInfo.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
    async handleSubscriptionPurchase(planName: string) {
      try {
        const subscriptionPurchase = await purchaseSubscription(planName)
        if (subscriptionPurchase.success) {
          this.getSubscriptionStatus()
          return { success: true, data: subscriptionPurchase.data, message: subscriptionPurchase.message }
        }
        const msg = subscriptionPurchase.message.length == 0 ? subscriptionPurchase.message : subscriptionPurchase.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
  },
})
