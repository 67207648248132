// src/stores/authStore.ts
import { defineStore } from 'pinia'
import { fetchMarketSignals, fetchUserInfo, MarketSignals, updateTradingViewUsername, User } from '../utils/api-helpers'
import { ApiError } from '../utils/api-client'

interface DashboardState {
  user: User | null
  marketSignals: MarketSignals[]
}

export enum UserSubscriptionStatusResp {
  none = 0,
  active = 1,
  pending = 2,
  expired = 3,
}

function getStatusEnum(status: number): UserSubscriptionStatusResp {
  return status as UserSubscriptionStatusResp
}

export const useDashboardStore = defineStore('dashboard', {
  state: (): DashboardState => ({
    user: null,
    marketSignals: [],
  }),
  getters: {},
  actions: {
    async fetchUserInfo() {
      try {
        const dashboardUserInfo = await fetchUserInfo()
        if (dashboardUserInfo.success) {
          this.user = dashboardUserInfo.data.user
          const transposedData = {
            ...dashboardUserInfo.data,
            subscriptionStatus: getStatusEnum(dashboardUserInfo.data.subscriptionStatus),
          }
          return { success: true, data: transposedData, message: dashboardUserInfo.message }
        }

        const msg = dashboardUserInfo.message.length == 0 ? dashboardUserInfo.message : dashboardUserInfo.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
    async fetchMarketSignals() {
      try {
        const marketSignalsInfo = await fetchMarketSignals()
        if (marketSignalsInfo.success) {
          this.marketSignals = marketSignalsInfo.data.marketSignals
          return { success: true, data: marketSignalsInfo.data.marketSignals, message: marketSignalsInfo.message }
        }
        const msg = marketSignalsInfo.message.length == 0 ? marketSignalsInfo.message : marketSignalsInfo.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
    async handleTradingViewUsernameUpdate(tradingViewUsername: string) {
      try {
        const updateResponse = await updateTradingViewUsername(tradingViewUsername)
        if (updateResponse.success) {
          this.fetchUserInfo()
          return { success: true, data: updateResponse.data, message: updateResponse.message }
        }
        const msg = updateResponse.message.length == 0 ? updateResponse.message : updateResponse.error
        return { success: false, message: msg, data: null }
      } catch (err: any) {
        if (err instanceof ApiError) {
          return { success: false, message: err.data?.message ?? err.message, data: null }
        }
        return { success: false, message: err.message, data: null }
      }
    },
  },
})
