<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)]">
    <template #left>
      <a
        class="bg-primary h-full flex items-center justify-center"
        style="width: 35vw"
        href="https://vta.one/"
        aria-label="Visit homepage"
      >
        <img class="max-h-20" fit="cover" src="/allwhite.png" />
      </a>
    </template>
    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[420px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <a class="py-4" href="https://vta.one/" aria-label="Visit homepage">
              <img class="max-h-10" fit="cover" src="/wordmark.png" />
            </a>
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'

const breakpoint = useBreakpoint()
</script>
