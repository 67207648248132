// src/api/userApi.ts
import { UserSubscriptionStatusResp } from '../stores/dashboard-store'
import { getRequest, postRequest, ApiResponse } from './api-client'

export interface User {
  memberId: number
  name: string
  email: string
  referralCode: string
  residence: string
  emailVerified: boolean
  createdAt: Date
  updatedAt: Date
  tradingViewUsername?: string
}

export interface CreateUserPayload {
  name: string
  email: string
  password: string
}

export const login = async (email: string): Promise<ApiResponse<{ otpSent: boolean }>> => {
  return await postRequest<{ otpSent: boolean }>('/auth/login', { email })
}

export const verifyLogin = async (email: string, otp: string): Promise<ApiResponse<{ token: string }>> => {
  return await postRequest<{ token: string }>('/auth/verify-login', { email, otp })
}

export const verifyEmail = async (token: string): Promise<ApiResponse<{ verifiedEmail: boolean }>> => {
  return await postRequest<{ verifiedEmail: boolean }>('/auth/verify-email', { token })
}

export const registerUser = async (payload: {
  email: string
  name: string
  referralCode: string
  residence: string
  tradingViewUsername: string
}): Promise<ApiResponse<{ registered: boolean; email: string }>> => {
  return await postRequest<{ registered: boolean; email: string }>('/user/register', payload)
}

export const logoutUser = async (): Promise<ApiResponse<{ user: User }>> => {
  return await postRequest<{ user: User }>('/user/logout')
}

export const fetchUserInfo = async (): Promise<
  ApiResponse<{
    user: User
    referralCount: number
    walletBalance: string
    subscriptionStatus: number
    subscriptionPlan: string
  }>
> => {
  return await getRequest<{
    user: User
    referralCount: number
    walletBalance: string
    subscriptionStatus: number
    subscriptionPlan: string
  }>('/user/info')
}

export const fetchUserProfile = async (): Promise<ApiResponse<{ user: User }>> => {
  return await getRequest<{ user: User }>('/user/profile')
}

export const fetchMarketSignals = async (): Promise<ApiResponse<{ marketSignals: MarketSignals[] }>> => {
  return await getRequest<{ marketSignals: MarketSignals[] }>('/user/market-signals')
}

export interface MarketSignals {
  id: string
  symbol: string
  link: string
  timeframe: string | null
  price: number | null
  systemGenerated: boolean
  description: string
  createdAt: Date
}
;[]

export const updateTradingViewUsername = async (
  tradingViewUsername: string,
): Promise<
  ApiResponse<{
    user: User
  }>
> => {
  return await postRequest<{
    user: User
  }>('/user/trading-view-username/update', { tradingViewUsername })
}

export const fetchWalletInfo = async (): Promise<
  ApiResponse<{
    commissionsLogs: Commissions[]
    walletDetails: WalletDetails
    transfers: Transfers[]
    deposits: Deposits[]
  }>
> => {
  return await getRequest<{
    commissionsLogs: Commissions[]
    walletDetails: WalletDetails
    transfers: Transfers[]
    deposits: Deposits[]
  }>('/wallet/info')
}

export interface Commissions {
  createdAt: Date
  currency: string
  amount: string
  level: number
  generatedBy: {
    memberId: number
  }
  subscription: {
    amount: string
    plan: {
      name: string
    }
  }
}

export interface WalletDetails {
  id: string
  updatedAt: Date
  currency: string
  balance: string
  payouts: WalletPayouts[]
}

export interface WalletPayouts {
  createdAt: Date
  updatedAt: Date
  currency: string
  amount: string
  fees: string
  status: string
  transactionId: string | null
}

export interface Transfers {
  createdAt: Date
  currency: string
  amount: string
  status: string
  subscriptionId: string | null
  subscription: {
    plan: {
      name: string
    }
  } | null
  type: string
  sourceWallet: {
    user: {
      memberId: number
    }
  }
  destinationWallet: {
    user: {
      memberId: number
    }
  } | null
}

export interface Deposits {
  createdAt: Date
  currency: string
  amount: string
  status: number
  transactionHash: string
  txnId: string
}

export const fetchDepositAddress = async (): Promise<
  ApiResponse<{
    address: string
    qrCode: {
      status: string
      qr_code: string
      payment_uri: string
    }
  }>
> => {
  return await postRequest<{
    address: string
    qrCode: {
      status: string
      qr_code: string
      payment_uri: string
    }
  }>('/wallet/deposit-address')
}

export const validateReceiver = async (
  receiverEmail: string,
): Promise<
  ApiResponse<{
    email: string
    name: string
    memberId: number
  }>
> => {
  return await postRequest<{
    email: string
    name: string
    memberId: number
  }>('/wallet/validate-receiver', { email: receiverEmail })
}

export const initiateTransfer = async (
  receiverEmail: string,
  amount: string,
): Promise<
  ApiResponse<{
    initiated: boolean
    amount: string
    email: string
    transferId: string
  }>
> => {
  return await postRequest<{
    initiated: boolean
    amount: string
    email: string
    transferId: string
  }>('/wallet/initiate-transfer', { email: receiverEmail, amount: parseFloat(amount) })
}

export const confirmTransfer = async (
  transferId: string,
  otp: string,
): Promise<
  ApiResponse<{
    transferred: boolean
    amount: string
    transferId: string
  }>
> => {
  return await postRequest<{
    transferred: boolean
    amount: string
    transferId: string
  }>('/wallet/confirm-transfer', { transferId, otp })
}

export const requestWalletPayout = async (
  walletAddress: string,
  amount: string,
): Promise<
  ApiResponse<{
    createdPayout: boolean
    payoutRequest: WalletPayouts
  }>
> => {
  return await postRequest<{
    createdPayout: boolean
    payoutRequest: WalletPayouts
  }>('/wallet/request-payout', { walletAddress, amount })
}

export const fetchSubscriptionStatus = async (): Promise<
  ApiResponse<{
    subscriptionStatus: number
    subscription: Subscription
  }>
> => {
  return await getRequest<{
    subscriptionStatus: number
    subscription: Subscription
  }>('/subscription/status')
}

export interface Subscription {
  createdAt: Date
  updatedAt: Date
  currency: string
  amount: string
  status: string
  startDate: Date
  endDate: Date
  plan: { name: string; duration: number }
  payments: Transfers[]
}

export const fetchSubscriptionPlans = async (): Promise<
  ApiResponse<{
    plans: {
      id: string
      name: string
      description: string
      usdPrice: string
      inrPrice: string
      duration: number
      features: any
      isActive: boolean
      createdAt: Date
      updatedAt: Date
    }[]
  }>
> => {
  return await getRequest<{
    plans: {
      id: string
      name: string
      description: string
      usdPrice: string
      inrPrice: string
      duration: number
      features: any
      isActive: boolean
      createdAt: Date
      updatedAt: Date
    }[]
  }>('/subscription/status')
}

export const purchaseSubscription = async (
  planName: string,
): Promise<
  ApiResponse<{
    subscriptionPurchased: boolean
    amount: string
    subscription: {
      id: string
      userId: string
      status: string
      startDate: Date
      endDate: Date
      amount: string
      currency: string
      planId: string
      createdAt: Date
      updatedAt: Date
    }
  }>
> => {
  return await postRequest<{
    subscriptionPurchased: boolean
    amount: string
    subscription: {
      id: string
      userId: string
      status: string
      startDate: Date
      endDate: Date
      amount: string
      currency: string
      planId: string
      createdAt: Date
      updatedAt: Date
    }
  }>('/subscription/purchase', { planName })
}

export const fetchReferralStats = async (): Promise<ApiResponse<ReferralStats>> => {
  return await getRequest<ReferralStats>('/referral/stats')
}

export interface ReferralStats {
  referralCode: string
  l1Platinum: number
  l2Platinum: number
  l3Platinum: number
  l1Gold: number
  l2Gold: number
  l3Gold: number
  l1Silver: number
  l2Silver: number
  l3Silver: number
  l1Bronze: number
  l2Bronze: number
  l3Bronze: number
}

export const fetchReferralTree = async (): Promise<
  ApiResponse<{
    tree: TransformedReferralTree
  }>
> => {
  return await getRequest<{
    tree: TransformedReferralTree
  }>('/referral/tree')
}

export interface TransformedReferralTree {
  memberId: number // or number
  name: string
  createdAt: Date // or string]
  subscription: {
    subscriptionStatus: UserSubscriptionStatusResp
    subscriptionPlan: string | null
  }
  children: Referral[] // Array of referrals
}

export interface Referral {
  memberId: number // or number, depending on your data type
  name: string
  createdAt: Date // or string, depending on how you store dates
  subscription: {
    subscriptionStatus: UserSubscriptionStatusResp
    subscriptionPlan: string | null
  }
  children: Referral[] // Array of referrals
}
